import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import "./individuel.css";
import * as Yup from "yup";
import Checkbox from "./checkbox.js";
import "react-toastify/dist/ReactToastify.css";
import { InputTags } from "react-bootstrap-tagsinput";
import "react-bootstrap-tagsinput/dist/index.css";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const validationSchema = Yup.object().shape({
    sousContactTel: Yup.string().required("Ce champ est requis"),
    sousAdressePostale: Yup.string(),
    sousNom: Yup.string().required("Ce champ est requis"),
    sousPrenom: Yup.string().required("Ce champ est requis"),
    sousDateNaissance: Yup.string(),
});

const Souscripteur = () => {
    const {individuel, individuelNextStep, setSelectedOption, setSelectedItem, isCheck, setIsCheck, showErrorMessage, error, numPolices, setNumPolices,
        isChecked, setIsChecked, handleCheckboxChange, setIndividuel, handleEmpty2, setUpdateContextStoreData, userCheckedData,
    } = useContext(AppContext);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [contrats, setContrats] = useState([]);
    const [message, setMessage] = useState("");

    //getting data from localstorage
    let data = JSON.parse(localStorage.getItem("data"));
    let response = data?.data.client.contrats;

    //tout cocher function
    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(response?.map((li) => li.NUMERO_POLICE));
        setContrats(response?.map((li) => li.LIBELLE_PRODUIT));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    //clicker par item function
    const handleClick = (e) => {
        const { id, checked, name } = e.target;
        let dataId = e.target.getAttribute("dataId");
        let newIsCheckList = isCheck;

        if (!isCheck.includes(Number(dataId))) {
            newIsCheckList = [...newIsCheckList, Number(dataId)];
            setIsCheck(newIsCheckList);
        } else {
            newIsCheckList = newIsCheckList.filter((item) => item !== Number(dataId));
            setIsCheck(newIsCheckList);
        }
        setIsCheckAll(newIsCheckList.length === response.length);
    };

    const catalog = response?.map(({ NUMERO_POLICE, index }) => {
        return (
            <>
                <Checkbox key={`${index}-police`} type="checkbox" name="police" dataId={NUMERO_POLICE} id={NUMERO_POLICE}
                          handleClick={handleClick} isChecked={isCheck.includes(NUMERO_POLICE)}/>
                <label className="btn btn-outline-light m-2" htmlFor={`${NUMERO_POLICE}`}>{" "}{NUMERO_POLICE}</label>
            </>
        );
    });

    const anotherCatalog = response?.map(
        ({ NUMERO_POLICE, LIBELLE_PRODUIT, index }) => {
            return (
                <>
                    <Checkbox key={`${index}-contrat`} type="checkbox" name="contrat" dataId={NUMERO_POLICE} id={LIBELLE_PRODUIT}
                              handleClick={handleClick} isChecked={isCheck.includes(NUMERO_POLICE)}/>
                    <label className="btn btn-outline-light m-2" htmlFor={`${LIBELLE_PRODUIT}`}>{" "}{LIBELLE_PRODUIT}</label>
                </>
            );
        }
    );

    //navigation initiation
    const navigate = useNavigate();
    const handleSubmit = (values) => {
        values.assure = userCheckedData.name + " " + userCheckedData.prenoms;
        values.defDateNaissance = userCheckedData.dob;
        individuelNextStep(values);
    };
    const handleReturn = (e) => {
        e.preventDefault();
        localStorage.clear();
        setIsChecked(false);
        handleEmpty2();
        setUpdateContextStoreData(false);
        navigate("/search");
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={individuel} enableReinitialize={true} onSubmit={handleSubmit}>
            {({ errors, touched, values }) => (
                <Form>
                    <h4 className="section-title section-title-mobile mb-4">INFORMATIONS SUR LE CLIENT</h4>
                    <div className="row flex cutom-box">
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="sousNom">
                                NOM <strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="sousNom" type="text" placeholder="NOM" readOnly={true}/>
                            <p className="text-red-600 dark:text-red-500">{errors.sousNom && touched.sousNom ? errors.sousNom : null}</p>
                        </div>
                        <div className="col-md-4 custom-with ">
                            <label className="block text-center fw-bold leading-3 mb-2"
                                   htmlFor="sousPrenom">
                                PRENOMS <strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full  p-2"
                                name="sousPrenom" id="sousPrenom" type="text" placeholder="PRENOMS" readOnly={true}/>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.sousPrenom && touched.sousPrenom ? errors.sousPrenom : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2"
                                   htmlFor="sousDateNaissance">DATE DE NAISSANCE</label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full  p-2"
                                name="sousDateNaissance" id="sousDateNaissance" type="date"
                                placeholder="DATE DE NAISSANCE" readOnly={true}/>
                            <p className=" text-red-800 dark:text-red-800">{" "}{errors.sousDateNaissance && touched.sousDateNaissance ? errors.sousDateNaissance : null}</p>
                        </div>
                        <div className="col-md-4 custom-with ">
                            <label className="block text-center fw-bold leading-3 mb-2"
                                   htmlFor="sousContactTel">
                                CONTACT TELEPHONIQUE{" "}<strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full  p-2"
                                name="sousContactTel" id="sousContactTel" type="text"
                                placeholder="CONTACT TELEPHONIQUE"/>
                            <p className="text-red-600 dark:text-red-500">{errors.sousContactTel && touched.sousContactTel ? errors.sousContactTel : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2"
                                   htmlFor="sousAdressePostale">ADRESSE POSTALE</label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full  p-2"
                                name="sousAdressePostale" id="sousAdressePostale" type="text"
                                placeholder="ADRESSE POSTALE"/>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.sousAdressePostale && touched.sousAdressePostale ? errors.sousAdressePostale : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2"
                                   htmlFor="numero_police">NUMEROS DE POLICES</label>
                            <InputTags className="input-tags appearance-none border-input custom-with-label font-mono w-full p-1"
                                values={numPolices} onTags={(value) => setNumPolices(value.values)}
                                placeholder="NUMERO DE POLICE"/>
                        </div>
                    </div>
                    <div className="flex items-center justify-start cutom-box my-2">
                        <div className="checkbox-container">
                            <input className="border-input" type="checkbox" id="my-checkbox" checked={isChecked}
                                   onChange={(e) => handleCheckboxChange(e)}/>
                            <label htmlFor="my-checkbox" className="flex items-center text-secondary fw-bold justify-center">Veuillez cocher cette case svp si le client est aussi le défunt.</label>
                        </div>
                    </div>

                    <div className="flex mt-4 justify-center align-center text-center btn-container souscripteur-btn-mobile">
                        <button onClick={(e) => handleReturn(e)} type="button"
                                className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                            <IoIosArrowBack className="mr-2"/> Précédent
                        </button>
                        <button type="submit" className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                            Suivant <IoIosArrowForward className="ml-2"/>
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Souscripteur;

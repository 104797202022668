import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import actions_config from "../../../../actions/config.js";
import Breadcrumb from "./Breadcrumb.js";
import FaqItemsContentViewer from './FaqItemsContentViewer';
import AlertContent from '../../../alertContent/AlertContent.js';
import {BiArrowBack} from "react-icons/bi";
import {IoIosArrowBack} from "react-icons/io";

export default function Viewer() {
    const defaultBreadCrumb = {
        key: 0,
        context: "index",
        title: "FAQ / Categories"
    };
    const [breadCrumbItems, setBreadCrumbItems] = useState([defaultBreadCrumb]);
    const [dataViewer, setDataViewer] = useState([]);
    const [itemsViewContent, setItemsViewContent] = useState([]);

    useEffect(() => {
        settleDefault();
    }, []);

    const settleDefault = () => {
        axios(actions_config.axios_config)
            .then(function (response) {
                let _ = response.data.data;
                let data = [];

                _.map((__) => {
                    data.push({ ...__, context: "category" })

                });
                setDataViewer(data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const renderBreadCrumbContainer = () => {
        return (
            <div className="card-title flex justify-between items-center p-3">
                <Breadcrumb breadcrumbs={breadCrumbItems} onClickGoTo={onClickGoTo} style={{color: '#ffffff!important'}} />
                <Link to="/assistance" type="button" className="btn bts-sm btn-light rounded-pill">
                        <span className="flex justify-center items-center">
                            <IoIosArrowBack size={20} /> <label className="d-none d-md-inline m-1">Retour</label>
                        </span>
                </Link>
            </div>
        )
    }

    const renderViewContent = () => {
        return (
            <FaqItemsContentViewer itemsContentView={itemsViewContent} />
        )
    }

    const requestItem = (_item) => {
        axios({
            ...actions_config.axios_config,
            method: 'post',
            data: qs.stringify({
                'token': `${_item.key}`
            }),

            url: actions_config.axios_base_url + "/list-items-category-faq"
        })
            .then(function (response) {
                let _ = response.data.data;
                let data = [];
                let keyContextValue = "category";

                if (_.contents.length === 0) {
                    _ = _.subcategories;
                    _.map((__) => {
                        data.push({ ...__, context: keyContextValue })
                    });
                } else {
                    keyContextValue = "content";
                    _ = _.contents;
                    _.map((__) => {
                        data.push({ ...__, context: keyContextValue })
                    });
                    setItemsViewContent(data)
                }
                setDataViewer(data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const onClickGoTo = (_item) => {
        if (_item.context === "index" && breadCrumbItems.length > 1) {
            setBreadCrumbItems([defaultBreadCrumb])
            settleDefault();
            setItemsViewContent([])
        } else if (_item.context === "category") {
            const indexGoTo = breadCrumbItems.findIndex((_v) => _v.key === _item.key);
            let _breadCrumbs = [];
            if (indexGoTo !== -1) {
                _breadCrumbs = breadCrumbItems.filter((_v, _i) => _i <= indexGoTo);
                setItemsViewContent([]);
                handleClickDataViewItem(_item);
                setBreadCrumbItems(_breadCrumbs);
            }
        } else {
            //
        }
    }

    const handleClickDataViewItem = (_item) => {
        setBreadCrumbItems([...breadCrumbItems, {
            key: _item.key,
            context: _item.context,
            title: _item.title
        }]);
        if (_item.context !== "content") {
            requestItem(_item);
        }
    }
    const renderDataViewerItem = (_item) => {
        return (
            <div key={_item.key} className="col-md-4 mb-2">
                <Link style={{ textDecoration: 'none', color: 'white' }} data-tour="step-1" onClick={() => handleClickDataViewItem(_item)}>
                    <div className="icon-box">
                        <h3>{_item.title}</h3>
                    </div>
                </Link>
            </div>
        )
    }

    if (dataViewer.length === 0) return <AlertContent />
    return (
        <>
            {renderBreadCrumbContainer()}
            <div className="card-content p-4 d-flex align-items-center justify-content-center">
                <div className="container d-flex flex-column justify-content-between " data-aos="fade-up" >
                    <div className="row mb-2 justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                        {itemsViewContent.length > 0 ? renderViewContent() : dataViewer.map((_item) => renderDataViewerItem(_item))}
                    </div>
                </div>
            </div>
        </>
    )
}
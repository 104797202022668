import axios from "axios";
import FormData from "form-data";
import React, { useContext, useState, useCallback } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./loginform.css";
import { CgPresentation } from "react-icons/cg";
import { AiOutlineArrowLeft, AiOutlineLogin } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_variables from "../../controllers/globalVar.js";
import Loader from "../../loading/Loading.js";
import {MdFollowTheSigns} from "react-icons/md";
import {IoIosArrowBack} from "react-icons/io";

const validationSchema = Yup.object().shape({
  nouveauCode: Yup.string().required("Ce champ est obligatoire"),
});

const GeneForm = () => {
  const {
    individuel,
    individuelNextStep,
    individuelPreviousStep,
    fileName,
    fileName2,
    fileName3,
    isCheck,
    selectedOption,
    setSelectedOption,
    selectedItem,
    setSelectedItem,
    declareAcceuil,
    setDeclareAcceuil,
    successful,
    setSuccessful,
    message,
    setMessage,
    showAlert,
    alert,
    researchResult,
    setSearchResult,
  } = useContext(AppContext);
  const { corporate, corporateNextStep, corporatePreviousStep } = useContext(
    AppContext
  );
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("NONE/NONE");
  const navigate = useNavigate();
  const initialValues = {
    nouveauCode: "",
  };
  const handleSubmit = useCallback(
    (values) => {
      setLoading(true);
      var data = new FormData();
      var config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${
          global_variables().SINISTRE_CARE_WEBSERVICE
        }/forget-declaration-number?contact_declarant=${values.nouveauCode}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      axios(config)
        .then(function(response) {
          console.log(response, "message");
          if (response.data.data.length === 0) {
            setLoading(false);
            toast.error(`${response.data.error}`, {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.success(`${response.data.data.message}`, {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            navigate("/login");
          }
        })
        .catch(function(error) {
          setLoading(false);
          toast.error(
            "Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard.",
            {
              position: "top-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            }
          );
          console.log(error);
        });
    },
    [selectedOption, selectedItem, setSearchResult, researchResult]
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
          <Form>
              <div className="card-title flex justify-center items-center py-3 text-2xl text-center renseignement">
                <CgPresentation size={50} className="mr-2"/> RENVOYER LE NUMERO DE DECLARATION
              </div>

              <div className="card-content p-4 lighest-main-login">
                <div className="flex justify-evenly my-3 items-center cutom-box">
                  <div className="w-full mx-2 flex-col justify-center align-center code-box cutom-with">
                    <Field
                        className="flex justify-around p-2 items-center text-center appearance-none border-input custom-with-label font-mono w-full"
                        name="nouveauCode"
                        id="nouveauCode"
                        type="text"
                        placeholder="Numéro de téléphone associé à la déclaration"
                    />
                    <p className="text-red-600 dark:text-red-500">
                      {errors.nouveauCode && touched.nouveauCode
                          ? errors.nouveauCode
                          : null}
                    </p>
                  </div>
                </div>

                {loading ? (
                    <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                    >
                      <Loader/>
                    </div>
                ) : (
                    <div className="flex justify-center align-center text-center my-3">
                      <Link to="/login" type="button" className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                        <IoIosArrowBack className="mr-2"/> Retour
                      </Link>
                      <button type="submit" className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                        Envoyer <AiOutlineLogin className="ml-2"/>
                      </button>
                    </div>
                )}
              </div>
          </Form>
        )}
    </Formik>
  );
};

export default GeneForm;

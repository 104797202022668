import React, { useState, useEffect } from 'react';
import { BsFileEarmarkLock2 } from 'react-icons/bs';
import { GiCheckMark } from 'react-icons/gi';
import { RxCross2 } from 'react-icons/rx';
import { FaRegClock } from "react-icons/fa";
import './chronogramme.css';
import {Link} from "react-router-dom";
import {AiOutlineArrowLeft} from "react-icons/ai";

const Chronogramme = () =>
{
    const [myData, setMyData] = useState({});
    const [updateContextStoreData, setUpdateContextStoreData] = useState(false);
    let data = JSON.parse(localStorage.getItem("user"));

    useEffect(() =>
    {
        let storeData = localStorage.getItem('user');
        if (storeData !== null)
        {
            storeData = JSON.parse(storeData);
            setMyData(storeData);
        }
    }, [updateContextStoreData])
    return (
        <div className="justify-content-center align-items-center bg-light m-4 m-md-0 p-4 rounded-4">
            <ul className="timeline timeline-horizontal">
                <div className="row">
                    <div className="col-md-4">
                        <li className="timeline-item">
                            <div className={`${myData.data?.chronogramme.declaration.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.declaration.status === 'validé' ? 'font-warning' : 'font-secondary'} timeline-badge`}>
                                {myData.data?.chronogramme.declaration.status === 'validé' ?
                                    <GiCheckMark size={45} /> :
                                    <BsFileEarmarkLock2 size={45} />}
                            </div>
                            <div className={`${myData.data?.chronogramme.declaration.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.declaration.status === 'validé' ? 'font-warning' : 'font-secondary'} rounded-xl shadow-md timeline-panel`} style={{minHeight: '130px'}}>
                                <div className="timeline-heading text-start">
                                    <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.declaration.title}</h4>
                                    <p><small className="flex align-items-center text-light"><FaRegClock
                                        className="mr-1"/> {myData.data?.chronogramme.declaration.details.date_creation}
                                    </small></p>
                                </div>
                                <div className="timeline-body">
                                    <p className="flex leading-tight text-start">
                                        {myData.data?.chronogramme.declaration.details.status === true ?
                                            <GiCheckMark size={20} /> :
                                            <RxCross2 size={20} />}
                                        {myData.data?.chronogramme.declaration.details.libelle}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </div>

                    <div className="col-md-4">
                        <li className="timeline-item">
                            <div className={`h-full w-1 ${myData.data?.chronogramme.analyse.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.analyse.status === 'validé' ? 'font-warning' : 'font-secondary'} pointer-events-none timeline-badge`}>
                                {myData.data?.chronogramme.analyse.status === 'validé' ?
                                    <GiCheckMark size={45} /> :
                                    <BsFileEarmarkLock2 size={45} />}
                            </div>
                            <div className={`${myData.data?.chronogramme.analyse.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.analyse.status === 'validé' ? 'font-warning' : 'font-secondary'} rounded-xl shadow-md timeline-panel`} style={{minHeight: '130px'}}>
                                <div className="timeline-heading text-start">
                                    <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.analyse.title}</h4>
                                </div>
                                <div className="timeline-body">
                                    {myData.data?.chronogramme.declaration.status === 'validé'
                                        ? <p className="flex leading-tight text-start">
                                            {myData.data?.chronogramme.analyse.status === 'validé'
                                                ? <GiCheckMark size={20} />
                                                : (myData.data?.chronogramme.analyse.details.pieces.status === true ?
                                                    <GiCheckMark size={20} /> :
                                                    <RxCross2 size={20} />)
                                            }
                                            {myData.data?.chronogramme.analyse.details.pieces.libelle}</p>
                                        :
                                        <p className="flex leading-tight text-start">En attente de la validation de la déclaration de sinistre</p>
                                    }
                                    {/*{myData.data?.chronogramme.analyse.details.polices.map((police, index) => {*/}
                                    {/*    return (*/}
                                    {/*        <p key={index} className="flex leading-tight text-start">*/}
                                    {/*            {myData.data?.chronogramme.analyse.status === 'validé'*/}
                                    {/*                ? <GiCheckMark size={20} />*/}
                                    {/*                : (police.status === true ?*/}
                                    {/*                    <GiCheckMark size={20} /> :*/}
                                    {/*                    <RxCross2 size={20} />)*/}
                                    {/*            }*/}
                                    {/*            {police.libelle}*/}
                                    {/*        </p>*/}
                                    {/*    )*/}
                                    {/*})}*/}
                                </div>
                            </div>
                        </li>
                    </div>

                    <div className="col-md-4">
                        <li className="timeline-item">
                            <div className={`h-full w-1 ${myData.data?.chronogramme.reglement.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.reglement.status === 'validé' ? 'font-warning' : 'font-secondary'} pointer-events-none timeline-badge`}>
                                {myData.data?.chronogramme.reglement.status === 'validé' ?
                                    <GiCheckMark size={45} /> :
                                    <BsFileEarmarkLock2 size={45} />}
                            </div>
                            <div className={`${myData.data?.chronogramme.reglement.status === 'en cours' ? 'font-info' : myData.data?.chronogramme.reglement.status === 'validé' ? 'font-warning' : 'font-secondary'} rounded-xl shadow-md timeline-panel`} style={{minHeight: '130px'}}>
                                <div className="timeline-heading text-start">
                                    <h4 className="font-semibold text-lg mb-2">{myData.data?.chronogramme.reglement.title}</h4>
                                </div>
                                <div className="timeline-body">
                                    {myData.data?.chronogramme.reglement.details.polices.map((police, index) => {
                                        return (
                                            <p key={index} className="flex leading-tight text-start">
                                                {myData.data?.chronogramme.reglement.status === 'validé'
                                                    ? <GiCheckMark size={20} />
                                                    : (police.status === true ? <GiCheckMark size={20} /> : (police.status === false ?
                                                        <RxCross2 size={20} color='#ef4444d9'/> : ''))
                                                }
                                                {police.libelle}
                                            </p>
                                        )
                                    })}
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
            </ul>
        </div>
    );
};

export default Chronogramme;


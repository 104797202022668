import React from 'react'
import './successContainer.css'
import Success from '../individuelles/Success.js';

const SuccessContainer = () =>
{
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center">
            <div className="background" style={{backgroundImage: 'url(./img/backgrounds/home2.jpg)'}}></div>
            <div className="container" data-aos="fade-up">
                <Success/>
            </div>
        </section>
    )
}

export default SuccessContainer

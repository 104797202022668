import React from 'react'
import '../globalStyle.css';
import {Link} from "react-router-dom";
import {AiOutlineArrowLeft, AiOutlineCheck} from "react-icons/ai";
import {BiArrowBack} from "react-icons/bi";
import {IoIosArrowBack} from "react-icons/io";

const ProductionPieceContainer = () =>
{
    return (
        <section id='hero' className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className="container" data-aos="fade-up">
                <div className="justify-content-center align-items-center font-primary p-5 rounded-4" data-aos="zoom-in" data-aos-delay="150">
                    <div className="assistanceContainer" data-aos="fade-up">
                        <h1 className='bvn-phrase mb-4'>Guide de production de pièces</h1>
                        <h2 className="plate-form text-secondary">Disponible bientôt !</h2>
                        <div className="flex justify-center align-center info-sous-btn mt-3">
                            <Link to="/assistance" type="button" className="btn bts-sm btn-light rounded-pill">
                                <span className="flex justify-center items-center">
                                    <IoIosArrowBack className="mr-2" /> Retour
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductionPieceContainer

import React from 'react';
import './rdv.css'
import RdvMulti from './RdvMulti.js';

const RdvContainer = () => {
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className="container mt-16" data-aos="fade-up">
                <div className="justify-content-center align-items-center rounded-4" data-aos="zoom-in" data-aos-delay="250">
                    <RdvMulti/>
                </div>
            </div>
        </section>
    )
}

export default RdvContainer
import axios from "axios";
import qs from "qs";
import React, { useContext, useState, useCallback, useEffect } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./loginform.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineLogin,} from "react-icons/ai";
import { MdFollowTheSigns } from "react-icons/md";
import global_variables from "../../controllers/globalVar.js";
import Loader from "../../loading/Loading.js";
import {FaUserTie} from "react-icons/fa";
import SouscripteurInfo from "../../declare/recherche/form/SouscripteurInfo";
import {IoIosArrowBack} from "react-icons/io";
import {BiSearchAlt} from "react-icons/bi";

const validationSchema = Yup.object().shape({codeinput: Yup.string().required("Ce champ est obligatoire")});

const LoginForm = () => {
    const {individuel, individuelNextStep, individuelPreviousStep, fileName, fileName2, fileName3, isCheck, selectedOption, setSelectedOption, selectedItem,
        setSelectedItem, declareAcceuil, setDeclareAcceuil, successful, setSuccessful, message, setMessage, showAlert, alert, researchResult, setSearchResult,
    } = useContext(AppContext);
    const { corporate, corporateNextStep, corporatePreviousStep } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verifyCode, setVerifyCode] = useState(false);
    const [url, setUrl] = useState("NONE/NONE");
    const [usedValues, setUsedValues] = useState({});
    const navigate = useNavigate();
    const initialValues = {codeinput: ""};

    const handleNouveauCode = () => {navigate("/newcode");};

    const handleSubmit = useCallback((values) => {
        if (!values.codeinput)
            return null;
        var data = qs.stringify({numero_declaration: `${values.codeinput}`});
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/show-declaration`,
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            data: data,
        };
        setLoading(true);

        axios(config)
            .then((response) => {
                if (response.data.data.length === 0) {
                    setLoading(false);
                    toast.error(`${response.data.error}`, {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                } else {
                    localStorage.setItem("code", JSON.stringify(response.data));
                    toast.success("Numero de déclaration vérifié ! Veuillez renseigner le code de suivi.", {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    navigate("/readcode");
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Désolé, votre demande n’a pas abouti. Veuillez réessayer svp.",
                    {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    }
                );
                setVerifyCode(true);
            });
        }, [selectedOption, selectedItem, setSearchResult, researchResult]
    );

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={handleSubmit}>
            {({ errors, touched }) => (
                <Form>
                    <div className="card-title flex justify-center items-center py-3 text-3xl text-center renseignement">
                        <MdFollowTheSigns size={50} className="mr-2" /> SUIVRE MON DOSSIER
                    </div>
                    <div className="card-content p-4 lighest-main-login">
                        <div className="flex justify-evenly my-3 items-center cutom-box">
                            <div className="w-full mx-2 flex-col justify-center align-center code-box cutom-with">
                                <Field className="flex justify-around p-2 items-center text-center appearance-none border-input custom-with-label font-mono w-full"
                                    name="codeinput" id="codeinput" type="text" placeholder="Entrez votre numéro de déclaration" />
                                <p className="text-xl text-red-600 dark:text-red-500">{errors.codeinput && touched.codeinput ? errors.codeinput : null}</p>
                            </div>
                        </div>

                        {loading
                            ? (<div style={{display: "flex", justifyContent: "center", alignItems: "center",}}><Loader /></div>)
                            : (
                                <div className="flex justify-center align-center text-center my-3">
                                    <Link to="/" type="button" className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                                        <IoIosArrowBack className="mr-2"/> Retour
                                    </Link>
                                    <button type="submit" className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                                        {verifyCode ? 'Renvoyer le code' : 'Vérifier' } <AiOutlineLogin className="ml-2" />
                                    </button>
                                </div>
                            )
                        }

                        <div className="my-3 btn-container souscripteur-btn-mobile">
                            <div className="w-full flex justify-center items-center renvoyer-numero-container">
                                <p className="text-xl m-4 leading-3 avez-vous-oublier" style={{color: '#001e60'}}>Vous avez oublié votre numéro de déclaration ?</p>
                                <button type="button" onClick={handleNouveauCode} className="flex justify-center items-center p-2 text-secondary font-medium border-input">
                                    Demandez-le ici
                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default LoginForm;
import React from 'react';
import './piecehome.css';
import TableGrid from './TableGrid.js';
import Chronogramme from '../home/Chronogramme.js';
import ChronogrammeMobile from "../home/chronogrammeMobile";

const PieceHome = () => {
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className='container login-inner m-5'>
                <div className="justify-content-center align-items-center rounded-4" data-aos="zoom-in" data-aos-delay="250">
                    <TableGrid/>
                </div>
            </div>
        </section>
    )
}

export default PieceHome

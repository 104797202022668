import React from 'react'
import SinistreCourier from './SinistreCourier.js';

const CourierSinistreMain = () => {
    return (
        <section style={{ backgroundImage: `url(./img/backgrounds/home2.jpg)`, top: 'center', }} id="hero" className="in-containerlog d-flex align-items-center justify-content-center">
            <div className="container login-inner" data-aos="fade-up">
                <div className="row justify-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="col-12 login-container-mobile">
                        <SinistreCourier />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CourierSinistreMain

import React from 'react'
import {Link, useLocation} from 'react-router-dom';
import Contact from "./Contact.js";

const Footer = () => {
    let date = new Date()
    let location = useLocation();
    let locationPath = location.pathname;

    return (
        <footer id="footer">
            {locationPath === "/" ? (<div className="container"><Contact/></div>) : ""}
            <div className="copyright d-flex align-items-center justify-content-between">
                <span className="me-3">Vous pensez qu'un de vos proches disparus a un contrat à terme chez nous ? <Link to="https://www.jarstechnologies.com/" target="_blank" style={{color: "#ffc10794", fontWeight: 'bold'}}>Cliquez ici </Link></span>
                <span>Copyright © {date.getFullYear()} | By <Link style={{color: '#ffc10794', textDecoration: 'none', fontWeight: 'bold'}} to="https://www.jarstechnologies.com/" target="_blank"> JARS Technologies</Link></span>
            </div>
        </footer>
    )
}

export default Footer

import React from "react";
import { Link } from "react-router-dom";
import { TfiWrite } from "react-icons/tfi";
import { BiSupport } from "react-icons/bi";
import { MdFollowTheSigns } from "react-icons/md";
import "./landing.css";

const LandingPage = () => {
    // if (localStorage.length > 0) {
    //     localStorage.clear();
    // } else {
    //     console.log("localStorage is empty.");
    // }
    let data = JSON.parse(localStorage.getItem("user"));

    return (
        <section id="hero" className="d-flex align-items-end justify-content-center" style={{backgroundImage: 'url(./img/backgrounds/home2.jpg)', backgroundPosition: '50% 70%'}}>
            <div className="overlay"></div>
            <div className="container mb-36 mb-md-5" data-aos="fade-up">
                <div className="justify-content-center align-items-center px-5 py-2" data-aos="fade-up" data-aos-delay="150">
                    <h2 className="fw-bold">Bienvenue sur Sinistre Online !</h2>
                    <h5 className="px-md-5">Nous sommes de tout cœur avec vous, prêts à vous accompagner à chaque étape de votre déclaration de sinistre en ligne.</h5>
                </div>
                <div className="d-flex align-content-center justify-content-center" data-aos="zoom-in" data-aos-delay="250">
                    <div className="mx-1 mx-md-3">
                        <Link className="box-link" data-tour="step-1" to="info">
                            <div className="icon-box align-items-center justify-content-center px-md-5 py-md-4" style={{backgroundColor: '#0785bb'}}>
                                <div className="mb-2" style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                                    <TfiWrite size={30}/>
                                </div>
                                <h5 className="d-none d-md-block fw-bold">Je déclare <br/> un sinistre</h5>
                                <small className="d-block d-md-none fw-bold">Je déclare <br/> un sinistre</small>
                            </div>
                        </Link>
                    </div>
                    <div className="mx-1 mx-md-3">
                        <Link className="box-link" data-tour="step-2" to={(data !== null) ? 'suivrehome' : 'login'}>
                            <div className="icon-box align-items-center justify-content-center px-md-5 py-md-4" style={{backgroundColor: '#027092'}}>
                                <div className="mb-2" style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                                    <MdFollowTheSigns size={30}/>
                                </div>
                                <h5 className="d-none d-md-block fw-bold">Je suis ma <br/> déclaration</h5>
                                <small className="d-block d-md-none fw-bold">Je suis ma <br/> déclaration</small>
                            </div>
                        </Link>
                    </div>
                    <div className="mx-1 mx-md-3">
                    <Link className="box-link" data-tour="step-3" to="assistance">
                        <div className="icon-box align-items-center justify-content-center px-md-5 py-md-4" style={{backgroundColor: '#005780'}}>
                            <div className="mb-2" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {/*<img src="./img/icons/assistance.png" width={75} alt="assistance"/>*/}
                                <BiSupport size={30}/>
                            </div>
                            <h5 className="d-none d-md-block fw-bold">Je me fais <br/> assister</h5>
                            <small className="d-block d-md-none fw-bold">Je me fais <br/> assister</small>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LandingPage;

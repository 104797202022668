import React, { useEffect, useMemo, useState, useContext } from "react";
import "./tablegrid.css";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import {AiFillFileText} from "react-icons/ai";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { AppContext } from "../../../context/ContextApi";
import global_variables from "../../controllers/globalVar";
import AlertContent from "../../alertContent/AlertContent.js";
import Chronogramme from "./Chronogramme.js";

const TableGrid = () => {
    const {individuel, individuelNextStep, individuelPreviousStep, fileName, fileName2, fileName3, isCheck, selectedOption, setSelectedOption, selectedItem,
        setSelectedItem, declareAcceuil, setDeclareAcceuil, successful, setSuccessful, message, setMessage, showAlert, alert, researchResult, setSearchResult,
    } = useContext(AppContext);

    let dataTest = {
        data: {
            date_declaration: "",
            numero_declaration: "",
            declarant: "",
            polices: [{
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            },
            {
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            },
            {
                numero_police: "",
                statut_sinistre: "",
                statut_police: "",
            }],
        },
    };
    const [courier, setCourier] = useState([]);
    const [loading, setLoading] = useState(false);
    //getting data from localstorage
    let data = JSON.parse(localStorage.getItem("user")) || dataTest;
    let numDeclaration = useMemo(() => [data.data.numero_declaration], [data.data.numero_declaration,]);
    const navigate = useNavigate();
    const date = new Date(data.data.date_declaration);
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const formattedDate = date.toLocaleDateString("fr-FR", options);
    const [morePolicies, setMorePolicies] = useState(false);

    useEffect(() => {
        var data = qs.stringify({numero_declaration: `${numDeclaration}`,});
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-couriers-declaration`,
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            data: data,
        };

        axios(config).then((response) => {
                setCourier(response.data.data.nouveaux_courriers);
            })
            .catch((error) => {
                console.log(error);
            });
        }, [numDeclaration]);

    const handleCourierSinistre = async (id) => {
        setLoading(true);
        if (!id)
          return null;
        let key = id || "c92728a5-d0d0-4712-8f53-87c6aa2d2c68";
        var data = qs.stringify({token: `${key}`,});
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-couriers-sinistre`,
            headers: {"Content-Type": "application/x-www-form-urlencoded",},
            data: data,
        };
        axios(config).then((response) => {
            if (response.data.status === false && response.data.data.length === 0) {
                setLoading(false);
                toast.error(`${response.data.error}`, {
                    position: "top-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } else {
                localStorage.setItem("courrierSini", JSON.stringify(response.data));
                navigate("/couriersini");
            }
        })
        .catch((error) => {
            setLoading(false);
            toast.error("Désolé! Le service que vous tentez de joindre ne répond pas veuillez réessayer plus tard..", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            console.log(error);
        });
    };

    const seeMorePolicies = () => {
        setMorePolicies(true);
    };

    const reducePolicies = () => {
        setMorePolicies(false);
    };

    if (loading) return <AlertContent />;

    return (
        <div className="rounded-4 px-4">
            <h6 className="card-title items-center py-2 text-center">POLICES ASSOCIEES A LA DECLARATION</h6>
            <div className="card-content">
                {(data.data.polices.length > 0)
                    ? data.data.polices.map((police, index) => {
                        return (index === 0) ? (
                            <div className="card">
                                <div className="card-body text-light">
                                    <h6 className="card-title">Police N° {police.numero_police}</h6>
                                    <p className="card-text">{police.libelle_produit}</p>
                                    <label className={`${police.statut_sinistre_id === 5
                                        ? "bg-red-400 " : police.statut_sinistre_id === 7
                                            ? "bg-red-400 " : police.statut_sinistre_id === 6
                                                ? "bg-green-500" : police.statut_sinistre_id === 8
                                                    ? "bg-green-500" : "bg-yellow-500"} text-dark px-3 py-1 rounded-pill`}>
                                        {police.statut_sinistre ? police.statut_sinistre : "En cours d'analyse"}
                                    </label>
                                </div>
                            </div>
                        ) : null
                    })
                    : (<p className="text-center text-muted py-2">Aucune police n'est associée à cette déclaration.</p>)
                }

                {(data.data.polices.length > 1) ? (
                    (morePolicies === false) ? (
                        <div className="d-flex justify-content-center align-items-center">
                            <button onClick={seeMorePolicies} className="btn btn-sm btn-warning d-flex m-1">
                                <FaPlusCircle className="m-1"/>
                                <span>Voir plus de polices</span>
                            </button>
                        </div>
                    ) : (
                        <>
                            {data.data.polices.map((police, index) => (
                                index > 1 ? (
                                    <div className="card" key={police.numero_police}>
                                        <div className="card-body text-light">
                                            <h6 className="card-title">Police N° {police.numero_police}</h6>
                                            <p className="card-text">{police.libelle_produit}</p>
                                            <label className={`${police.statut_sinistre_id === 5
                                                ? "bg-red-400 " : police.statut_sinistre_id === 7
                                                    ? "bg-red-400 " : police.statut_sinistre_id === 6
                                                        ? "bg-green-500" : police.statut_sinistre_id === 8
                                                            ? "bg-green-500" : "bg-yellow-500"} text-dark px-3 py-1 rounded-pill`}>
                                                {police.statut_sinistre ? police.statut_sinistre : "En cours d'analyse"}
                                            </label>
                                        </div>
                                    </div>
                                ) : null
                            ))}
                            <div className="d-flex justify-content-center align-items-center">
                                <button onClick={reducePolicies} className="btn btn-sm btn-warning d-flex m-1">
                                    <FaMinusCircle className="m-1" />
                                    <span>Réduire les polices</span>
                                </button>
                            </div>
                        </>
                    )
                ) : null }

                <div className="border border-top d-flex justify-content-center align-items-center py-2">
                    <Link style={{textDecoration: "none"}} to="/piece">
                        <button className="btn btn-sm btn-info text-light d-flex m-1">
                            <AiFillFileText className="m-1"/>
                            <span>Pièces</span>
                        </button>
                    </Link>
                    <Link style={{textDecoration: "none", cursor: "pointer"}} to="/allmailContainer">
                        <button className="btn btn-sm btn-info text-light d-flex m-1">
                            <BsFillEnvelopeFill className="m-1"/>
                            <span>Courriers</span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default TableGrid;

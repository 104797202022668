import React, { useState, useEffect, useContext } from "react";
import Type from './Type.js';
import './typehome.css';
import { AppContext } from "../../../../context/ContextApi.js";
import {Link} from "react-router-dom";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import {FaUserTie} from "react-icons/fa";
import SouscripteurInfo from "../form/SouscripteurInfo";

export default function TypeHome()
{
    const { selectedOption, setSelectedOption, selectedItem, setSelectedItem } = useContext(AppContext)

    return (
        <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%' }}>
            <div className="container" data-aos="fade-up">
                <p className="rounded-5 px-4 py-1 content" style={{backgroundColor: "#0187be"}}>
                    Avant de commencer la déclaration, veuillez entrer svp les informations sur le client afin de rechercher son contrat.
                </p>
                <div data-aos="fade-up">
                    <div className="justify-content-center align-items-center px-4 rounded-4" data-aos="zoom-in" data-aos-delay="250">
                        <Type/>
                    </div>
                </div>
            </div>
        </section>
    );
}

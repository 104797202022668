import React from 'react'
import './suivrehome.css'
import TableGrid from './TableGrid.js';
import Chronogramme from './Chronogramme.js';

const SuivreHome = () => {
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className='container mt-4'>
                <div className='row'>
                    <div className="scrollable-element col-md-9 px-md-3">
                        <Chronogramme/>
                    </div>
                    <div className="scrollable-element col-md-3 px-md-3">
                        <TableGrid/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SuivreHome
import React, { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import global_variables from "../../controllers/globalVar.js";
import { IoIosSend, IoMdTrash } from "react-icons/io";
import { AppContext } from "../../../context/ContextApi";
import {AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineFile, AiFillPlusCircle,} from "react-icons/ai";
import Loader from "../../loading/Loading.js";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { CgEye } from "react-icons/cg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Button2 from "react-bootstrap/Button";
import Modal2 from "react-bootstrap/Modal";

const style = {
    margin: 12,
    height: 700,
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

function ImageUpload() {
    const newplugin = defaultLayoutPlugin();
    const {individuel, individuelNextStep, individuelPreviousStep, setFileName, setFileName2, setFileName3, staticFields, setStaticFields, staticSelectedCheckboxes,
        setStaticSelectedCheckboxes, dynamicSelectedCheckboxes, setDynamicSelectedCheckboxes, newPieceDynamicBox, setNewPieceDynamicBox, newPieceDynamicFields, setNewPieceDynamicFields,
    } = useContext(AppContext);
    const [imageFields, setImageFields] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);
    const [isShow, setIsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(true);
    const [done, setDone] = useState(false);
    const [pdfFile, setPDFfile] = useState(null);
    const [viewPdf, setViewPdf] = useState("");
    const [open, setOpen] = React.useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [libelleList, setLibelleList] = useState([]);

    let data = JSON.parse(localStorage.getItem("user"));
    let numDeclaration = useMemo(() => [data.data.numero_declaration], [data.data.numero_declaration]);

    useEffect(() => {
        var config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/list-pieces`,
            headers: {},
        };
        axios(config)
            .then(function(response) {
                if (response.data.status === true) {
                    setLibelleList(response.data.data);
                } else {
                    setLibelleList([]);
                }
            })
            .catch(function(error) {
                toast.error(
                    "Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.",
                    {
                        position: "top-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    }
                );
                console.log(error);
            });
    }, []);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const fileType = ["application/pdf"];
    const PreviewComponent = ({ field }) => {
        if (previewImages[field.id].files) {
            if (
                previewImages[field.id].files.type.startsWith("image/") ||
                previewImages[field.id].files.type.startsWith("jpg/") ||
                previewImages[field.id].files.type.startsWith("png/") ||
                previewImages[field.id].files.type.startsWith("tiff/") ||
                previewImages[field.id].files.type.startsWith("bmp/")
            ) {
            const imageUrl = URL.createObjectURL(previewImages[field.id].files);
            setShowModal(true);
            return (
                <Modal2 show={showModal}>
                    <Modal2.Body>
                        {" "} <img src={imageUrl} style={{ width: "900px", height: "400px" }} alt={`Preview ${field.id}`}/>
                    </Modal2.Body>
                    <Modal2.Footer>
                      <Button2 variant="secondary" onClick={() => handleModalClose(field.id)}>Fermer</Button2>
                    </Modal2.Footer>
                </Modal2>
            );
            } else if (
                previewImages[field.id].files &&
                fileType.includes(previewImages[field.id].files.type)
            ) {
            let reader = new FileReader();
            reader.readAsDataURL(previewImages[field.id].files);
            reader.onload = () => {
                setPDFfile(reader.result);
            };
            setOpen(true);

            return (
                <div>
                    <Modal open={open} onClose={() => handleClose(field.id)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">Visualisation</Typography>
                                <CloseIcon onClick={() => handleClose(field.id)} style={{ cursor: "pointer" }}/>
                            </div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
                                {pdfFile && (
                                    <>
                                        <Viewer fileUrl={pdfFile} pugins={[newplugin]} />
                                    </>
                                )}
                                {!pdfFile && <>No PDF</>}
                            </Worker>
                        </Box>
                    </Modal>
                </div>
            );
            } else {
                return <p>Preview not available for this file type.</p>;
            }
        } else {
            return null;
        }
    };

    const handleImageChange = async (event, index) => {
        const { name, files, id, value } = event.target;
        const newImageFields = [...imageFields];
        if (name === "file") {
            newImageFields[index][name] = await convertBase64(files[0]);
            newImageFields[index][id] = files[0];
        } else if (event.target.options[event.target.selectedIndex].id) {
            newImageFields[index].dropdownValue = event.target.value;
            newImageFields[index].newFileId = parseInt(
                event.target.options[event.target.selectedIndex].id,
              10
            );
        } else {
            return null;
        }
        setPreviewImages(newImageFields);
        setImageFields(newImageFields);
    };

    const handleTogglePreview = (id) => {
        const newImageFields = [...imageFields];
        newImageFields.forEach((field) => {
            if (field.id === id) {
                field.previewVisible = !field.previewVisible;
            }
          });
        setImageFields(newImageFields);
    };

    const handleClose = (id) => {
        setOpen(false);
        setPDFfile(null);
        const newImageFields = [...imageFields];
        newImageFields.forEach((field) => {
            if (field.id === id) {
                field.previewVisible = !field.previewVisible;
            }
        });

        setImageFields(newImageFields);
    };

    const handleModalClose = (id) => {
        setShowModal(false);
        const newImageFields = [...imageFields];
        newImageFields.forEach((field) => {
            if (field.id === id) {
                field.previewVisible = !field.previewVisible;
            }
        });
        setImageFields(newImageFields);
    };

    const handleAddField = () => {
        setIsShow(true);
        const newId = imageFields.length;
        const newField = {
            id: newId,
            file: null,
            previewVisible: false,
            text: "",
            dropdownValue: "",
        };
        setImageFields([...imageFields, newField]);
        setPreviewImages([...previewImages, null]);
    };

    const handleDeleteField = (id) => {
        const newImageFields = imageFields.filter((field) => field.id !== id);
        const newPreviewImages = previewImages.filter(
            (image, index) => index !== id
        );
        setImageFields(newImageFields);
        setPreviewImages(newPreviewImages);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        let pieces_beneficiaire = [];
        imageFields.forEach((each, index) => {
            pieces_beneficiaire.push([
                `${each.newFileId}`,
                `${each.dropdownValue}`,
                null,
                "scanne",
                `${each.file}`,
            ]);
        });

        var data = JSON.stringify({
            numero_declaration: `${numDeclaration}`,
            pieces_assure: [],
            pieces_beneficiaire: pieces_beneficiaire,
        });

        setLoading(true);
        var config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/add-documents`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
        .then((response) => {
            setLoading(false);
            toast.success("Féliciations! Fichier envoyer.", {
                position: "top-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setShow(false);
            // Refresh the page
            window.location.reload();
        })
        .catch((error) => {
            setLoading(false);
            toast.error("Désolé! Le service que vous tentez de joindre ne répond pas. Veuillez réessayer plus tard.", {
                  position: "top-center",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "dark",
            });
            console.log(error);
        });
    };

    return (
        <>
            {loading ? (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                    <Loader />
                </div>
            ) : show ? (
                <form onSubmit={handleSubmit}>
                    {isShow ? (
                        <>
                            {imageFields.map((field, index) => {
                                return (
                                    <div className="row flex cutom-box mb-3 transmettre" style={{backgroundColor: "rgb(0,0,0,0.2)", margin: "8px",}} key={field.id}>
                                        <div className="col-md-5 custom-with my-1">
                                            <select className="text-black bg-gray-200 p-2 rounded outline-none w-100" name="dropdown" value={field.dropdownValue}
                                                onChange={(e) => handleImageChange(e, field.id)}>
                                                <option value="">Selectionner le type de fichier</option>
                                                {libelleList.map((libelle, index) => {
                                                    return (
                                                        <option key={index} id={libelle.id} value={libelle.libelle}>{libelle.libelle}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className="col-md-7 flex custom-with my-1">
                                            <input className="bg-light p-1 w-100 border-input outline-none" name="file" type="file" id="files" style={{ cursor: "pointer" }}
                                              onChange={(event) =>
                                                handleImageChange(event, field.id)
                                              }
                                              required/>
                                            {field.previewVisible && (
                                              <PreviewComponent field={field} />
                                            )}
                                            <button type="button" onClick={() => handleTogglePreview(field.id)} className="btn mx-1 btn-info">
                                                <CgEye />
                                            </button>
                                            <button type="button" onClick={() => handleDeleteField(field.id)} className="btn btn-danger">
                                                <IoMdTrash />
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </>
                    ) : null}

                    <div className="d-flex justify-content-between align-items-center">
                        <div className="flex mb-2 cutom-box ml-4 text-nowrap">
                            <div className="flex w-1/2 mx-2 custom-with ajouter-autre ">
                                <button type="button" onClick={handleAddField}
                                    className="btn btn-sm btn-info">
                                    <div className="flex justify-center items-center">
                                        <AiFillPlusCircle size={25} className="mr-2" /> Ajouter une pièce
                                    </div>
                                </button>
                            </div>
                        </div>
                        {isShow ? (
                            <div className="flex items-end justify-end me-3">
                                <button className="btn btn-sm btn-warning"
                                    type="submit">
                                    <span className="flex">
                                        <IoIosSend size={20} className="mr-2" /> Transmettre
                                    </span>
                                </button>
                            </div>
                        ) : null}
                    </div>
                </form>
          ) : null}
        </>
    );
}

export default ImageUpload;

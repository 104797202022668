import React from 'react'
import { Link } from 'react-router-dom';
import {BiPhoneCall} from "react-icons/bi";
import {TfiEmail, TfiWrite} from "react-icons/tfi";
import {FaWhatsapp} from "react-icons/fa";
import {MdFollowTheSigns} from "react-icons/md";
import {FcAssistant} from "react-icons/fc";

const Contact = () => {

    return (
        <div className="d-flex align-content-center justify-content-center fw-bold mb-1">
            <div className="text-center mx-5 mx-md-3">
                <Link to="tel:13 54" className="text-decoration-none text-light">
                    <div className="align-items-center justify-content-center px-md-3">
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <BiPhoneCall size={20}/>
                        </div>
                        <small className="d-none d-md-block fw-bold">Pour les appels depuis <br/> la Côte d'Ivoire 1354</small>
                    </div>
                </Link>
            </div>
            <div className="text-center mx-5 mx-md-3">
                <Link to="mailto:ecoute.ci@nsiaassurances.com" className="text-decoration-none text-light">
                    <div className="align-items-center justify-content-center px-md-3">
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center",}}>
                            <TfiEmail size={20}/>
                        </div>
                        <small className="d-none d-md-block fw-bold">Par Mail <br/> ecoute.ci@nsiaassurances.com</small>
                    </div>
                </Link>
            </div>
            <div className="text-center mx-5 mx-md-3">
                <Link to="https://wa.me/+2250575102102" className="text-decoration-none text-light">
                    <div className="align-items-center justify-content-center px-md-3">
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <FaWhatsapp size={20}/>
                        </div>
                        <small className="d-none d-md-block fw-bold">Par WhatsApp <br/> +225 05 75 102 102</small>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default Contact

import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../context/ContextApi';
import axios from 'axios';
import qs from 'qs';
import './rdv.css'
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft, AiOutlineCheck } from "react-icons/ai";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../loading/Loading.js";
import global_variables from "../../controllers/globalVar";
import {IoIosArrowBack} from "react-icons/io";
import {BiSave} from "react-icons/bi";

const RdvRecap = () =>
{
    const { rdvPreviousStep, rdv ,setRdv} = useContext(AppContext)
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate()


    const handleValidation = () =>
    {
        // console.log('this is the rendez-vous',rdv)
        setLoading(true)
        // submit the form data to backend or do something else with the data
        let data = qs.stringify({
            'nom': `${rdv.rdvNom}`,
            'prenoms': `${rdv.rdvPrenom}`,
            'objet': `${rdv.rdvMotif}`,
            'date': `${rdv.rdvDate}`,
            'heure': `${rdv.rdvHeure}`,
            'contact': `${rdv.rdvTelephone}`,
            'residence':`${rdv.rdvResidence}`
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            // url: 'https://servo.integralewebservice.com/api/create-courier',
            url: `${global_variables().SINISTRE_CARE_WEBSERVICE}/create-courier`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios.request(config)
            .then((response) =>
            {
                setLoading(false);
                console.log(response.data);
                toast.success("Votre demande de RDV a bien été enregistrée. Vous serez contacté par un Gestionnaire Clientèle pour la confirmation.", {
                    position: "top-center",
                    autoClose: 1000000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                setRdv({
                    rdvNom: '',
                    rdvPrenom: '',
                    rdvTelephone: '',
                    rdvMotif: '',
                    rdvHeure: '',
                    rdvDate: '',
                    rdvResidence: '',
                });
                navigate('/assistance');
            })
            .catch((error) =>
            {
                setLoading(false)
                toast.error("Désolé, votre demande n’a pas abouti. Veuillez réessayer.", {
                    position: "top-center",
                    autoClose: 1000000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                console.log(error);
            });
    }

    const handleBack = (values) =>
    {
        rdvPreviousStep(values)
    }

    return (
        <div className="card-content lightest-main">
            <div className="py-3 login-container-mobile">
                <div className="py-3 login-container-mobile">
                    <div className="row flex cutom-box">
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvNom">NOM :</label>
                            <span className="text-xl text-dark font-mono font-semibold"> {rdv.rdvNom}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvPrenom">PRENOMS :</label>
                            <span className=" text-xl text-dark font-mono  font-semibold"> {rdv.rdvPrenom} </span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvResidence">RESIDENCE :</label>
                            <span className="text-xl text-dark font-mono  font-semibold">{rdv.rdvResidence}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block  mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvTelephone">TELEPHONE : </label>
                            <span className=" text-xl text-dark font-mono font-semibold">{rdv.rdvTelephone}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvDate">DATE :</label>
                            <span className=" text-xl text-dark font-mono  font-semibold">{rdv.rdvDate}</span>
                        </div>
                        <div className="col-md-6 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvHeure">HEURE : </label>
                            <span className=" text-xl text-dark font-mono  font-semibold">{rdv.rdvHeure}</span>
                        </div>
                        <div className="col-12 p-2 flex items-center custom-with">
                            <label className="block mr-2 text-grey-darker text-start text-xl leading-3 mb-2" htmlFor="rdvMotif">MOTIF : </label>
                            <span className="text-xl text-dark font-mono font-semibold">{rdv.rdvMotif}</span>
                        </div>
                    </div>
                </div>

                {loading ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></div> :
                    <div className="flex justify-center mt-3">
                        <div className="flex mt-4 justify-center align-center text-center btn-container souscripteur-btn-mobile">
                            <button onClick={() => handleBack("")} type="button"
                                    className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                                <IoIosArrowBack className="mr-2"/> Retour
                            </button>
                            <button onClick={handleValidation} type="submit"
                                    className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                                <AiOutlineCheck className="mr-2"/> Valider
                            </button>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default RdvRecap
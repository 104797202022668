import axios from "axios";
import qs from "qs";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Allmail from "./Allmail.js";
import Chronogramme from "../home/Chronogramme";
import ChronogrammeMobile from "../home/chronogrammeMobile";

export default function AllmailContainer() {
  return (
      <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
          <div className="container login-inner">
              <div className="justify-content-center align-items-center p-4 rounded-4" data-aos="zoom-in" data-aos-delay="250">
                  <Allmail/>
              </div>
          </div>
      </section>
  );
}

import React, { useContext, useState } from "react";
import { AppContext } from "../../../context/ContextApi";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "./individuel.css";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";

const validationSchema = Yup.object().shape({
    civilite: Yup.string().required("Ce champ est requis"),
    declaNom: Yup.string().required("Ce champ est requis"),
    declaPrenom: Yup.string().required("Ce champ est requis"),
    qualite_declarant: Yup.string().required("Ce champ est requis"),
    declaContact: Yup.string().required("Ce champ est requis"),
    autreContact: Yup.string(),
    declaAdressElectro: Yup.string(),
    declaAdressePost: Yup.string(),
});

const Declarant = () => {
    const { individuel, individuelNextStep, individuelPreviousStep } = useContext(AppContext);
    const [error, setError] = useState(false);
    const [message, setMessate] = useState("beginning");
    const handleSubmit = (values) => {
        individuelNextStep(values);
    };
    const handleBack = (values) => {
        individuelPreviousStep(values);
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={individuel} onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue }) => (
                <Form>
                    <h4 className="section-title section-title-mobile mb-4">INFORMATION SUR LE DECLARANT</h4>
                    <div className="row flex cutom-box">
                        <div className="col-12 custom-with">
                            <label className="fw-bold leading-3 mb-2">CIVILITE <strong
                                style={{color: "#cc992d"}}>*</strong></label>
                            <div className="flex mb-2 civilite-mobile-container">
                                <input id="Monsieur" name="civilite" type="radio" className="btn-check"
                                       autoComplete="off"
                                       onChange={(e) => {
                                           setFieldValue("civilite", e.target.id);
                                       }}/>
                                <label className="btn btn-outline-light m-2" htmlFor="Monsieur">Monsieur</label>
                                <input id="Madame" name="civilite" type="radio" className="btn-check" autoComplete="off"
                                       onChange={(e) => {
                                           setFieldValue("civilite", e.target.id);
                                       }}/>
                                <label className="btn btn-outline-light m-2" htmlFor="Madame">Madame</label>
                                <input id="Mademoiselle" name="civilite" type="radio" className="btn-check"
                                       autoComplete="off"
                                       onChange={(e) => {
                                           setFieldValue("civilite", e.target.id);
                                       }}/>
                                <label className="btn btn-outline-light m-2" htmlFor="Mademoiselle">Mademoiselle</label>
                            </div>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.civilite && touched.civilite ? errors.civilite : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="declaNom">
                                NOM <strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="declaNom" id="declaNom" type="text" placeholder="NOM"/>
                            <p className=" text-red-600 dark:text-red-500">{" "}{errors.declaNom && touched.declaNom ? errors.declaNom : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="declaPrenom">
                                PRENOMS <strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="declaPrenom" id="declaPrenom" type="text" placeholder="PRENOMS"/>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.declaPrenom && touched.declaPrenom ? errors.declaPrenom : null}</p>
                        </div>
                        <div className="col-md-4 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="qualite_declarant">
                                LIEN AVEC LE DÉFUNT <strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <select
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                id="qualite_declarant" name="qualite_declarant" placeholder="QUALITE"
                                onChange={(e) => {
                                    setFieldValue("qualite_declarant", e.target.value);
                                }}>
                                <option value={true} label="Sélectionner votre lien avec le défunt"></option>
                                <option value="ENFANT" label="ENFANT"></option>
                                <option value="PÈRE/MÈRE" label="PÈRE/MÈRE"></option>
                                <option value="CONJOINT(E)" label="CONJOINT(E)"></option>
                                <option value="ONCLE/TANTE" label="ONCLE/TANTE"></option>
                                <option value="EMPLOYEUR" label="EMPLOYEUR"></option>
                                <option value="COLLÈGUES" label="COLLÈGUES"></option>
                                <option value="AUTRES" label="AUTRES"/>
                            </select>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.qualite_declarant && touched.qualite_declarant ? errors.qualite_declarant : null}</p>
                        </div>
                        <div className="col-md-6 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="declaContact">
                                CONTACT TELEPHONIQUE{" "}<strong style={{color: "#cc992d"}}>*</strong>
                            </label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="declaContact" id="declaContact" type="text" placeholder="CONTACT TELEPHONIQUE"/>
                            <p className=" text-red-600 dark:text-red-500">{" "}{errors.declaContact && touched.declaContact ? errors.declaContact : null}</p>
                        </div>
                        <div className="col-md-6 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="autreContact">AUTRE
                                CONTACT{" "}</label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="autreContact" id="autreContact" type="text" placeholder="AUTRE CONTACT"/>
                            <p className=" text-red-600 dark:text-red-500">{" "}{errors.declaContact && touched.declaContact ? errors.declaContact : null}</p>
                        </div>
                        <div className="col-md-6 custom-with">
                            <label className="block text-center fw-bold font-mono leading-3 mb-2"
                                   htmlFor="declaAdressElectro">ADRESSE ELECTRONIQUE</label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full  p-2"
                                name="declaAdressElectro" id="declaAdressElectro" type="email"
                                placeholder="ADRESSE ELECTRONIQUE"/>
                            <p className="text-red-600 dark:text-red-500">{errors.declaAdressElectro && touched.declaAdressElectro ? errors.declaAdressElectro : null}</p>
                        </div>
                        <div className="col-md-6 custom-with">
                            <label className="block text-center fw-bold leading-3 mb-2" htmlFor="declaAdressePost">ADRESSE
                                POSTALE</label>
                            <Field
                                className="appearance-none border-input custom-with-label font-mono w-full p-2"
                                name="declaAdressePost" id="declaAdressePost" type="text"
                                placeholder="ADRESSE POSTALE"/>
                            <p className="text-red-600 dark:text-red-500">{" "}{errors.declaAdressePost && touched.declaAdressePost ? errors.declaAdressePost : null}</p>
                        </div>
                    </div>

                    <div
                        className="flex mt-4 justify-center align-center text-center btn-container souscripteur-btn-mobile">
                        <button onClick={() => handleBack(values)} type="button"
                                className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                            <IoIosArrowBack className="mr-2"/> Précédent
                        </button>
                        <button type="submit"
                                className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                            Suivant <IoIosArrowForward className="ml-2"/>
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Declarant;

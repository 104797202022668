import React from "react";
import "./info.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import {Link} from "react-router-dom";

const Info = () => {

    return (
        <section id="hero" className="d-flex align-items-center justify-content-center">
            <div className="background" style={{backgroundImage: 'url(./img/backgrounds/home2.jpg)'}}></div>
            <div className="container" data-aos="fade-up">
                <div className="justify-content-center align-items-center bvn bg-light p-4 rounded-4" data-aos="fade-up" data-aos-delay="150">
                    <h2 style={{color: '#001e60'}}>Assurément à vos côtés.</h2>
                    <div className="content text-secondary">
                        <p>
                            Parce que vous comptez pour nous, nous tenons à vous exprimer nos sincères condoléances
                            en ces moments si difficiles.
                            Afin de faciliter votre déclaration en ligne, nous vous invitons svp à vous munir de la
                            photo ou du scan de l’acte de décès du défunt / de la défunte.
                        </p>
                        <p>Vous avez aussi la possibilité de joindre d’autres pièces justificatives dont la liste
                            est à retrouver <Link to="" style={{color: '#001e60'}}>ici</Link>.</p>
                        <p>
                            La déclaration ne prendra que 10 minutes de votre précieux temps. <br/>
                            N’hésitez surtout pas à cliquez <Link to="/assistance" style={{color: '#001e60'}}>ici</Link> en cas de
                            besoin
                            d’assistance.
                        </p>
                    </div>
                    <div className="flex justify-center align-center text-center">
                        <Link to="/" type="button"
                              className="d-flex justify-center items-center btn bts-sm btn-primary mx-2 rounded-pill">
                            <IoIosArrowBack className="mr-2"/> Retour
                        </Link>
                        <Link to="/search" type="button"
                              className="d-flex justify-center items-center btn bts-sm btn-warning mx-2 rounded-pill">
                            Continuer <IoIosArrowForward className="ml-2"/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Info;

import React from 'react'
import DeclarerCourier from './DeclarerCourier.js';

const CourierContainer = () => {
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center" style={{backgroundColor: '#dfe2ea', top: "center", width: '100%'}}>
            <div className="container login-inner" data-aos="fade-up">
                <div className="justify-content-center align-items-center rounded-4" data-aos="zoom-in" data-aos-delay="250">
                    <DeclarerCourier/>
                </div>
            </div>
        </section>
    )
}

export default CourierContainer